(window as any).__Zone_ignore_on_properties = [
  {
    target: window,
    ignoreProperties: ['message'],
  },
];

(window as any).__zone_symbol__BLACK_LISTED_EVENTS = ['message'];

export { };
